import React from "react";
import { isMobile } from "@utils/index";
import loadable from "@loadable/component";

const PCApp = loadable(() => import("./layout/pc"));
const H5App = loadable(() => import("./layout/h5"));

function App() {
  return isMobile() ? <H5App /> : <PCApp />;
}

export default React.memo(App);
